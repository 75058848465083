import { Btn } from '@playbooks/interface/buttons';
import { FormDivInput } from '@playbooks/interface/forms';
import { Span } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { InputAppend, InputGroup, InputPrepend } from '@playbooks/interface/input-groups';
import { AuthBtn } from 'components/auth/auth-btn';
import { Skeleton } from 'molecules/skeletons';

const DownloadBtn = ({ type = 'primary', model, loading, onClick }) => {
	const props = { model, loading, onClick };

	// Render
	switch (type) {
		case 'form':
			return <DownloadFormBtn {...props} />;
		case 'icon':
			return <DownloadIconBtn {...props} />;
		case 'primary':
			return <DownloadPrimaryBtn {...props} />;
		case 'secondary':
			return <DownloadSecondaryBtn {...props} />;
	}
};

const DownloadFormBtn = ({ model, loading, onClick }) => (
	<InputGroup overflow='overflow-hidden'>
		<InputPrepend spacing='px-4'>$</InputPrepend>
		<FormDivInput
			type='text'
			variant='group'
			overflow='overflow-hidden overflow-x-scroll'
			spacing='py-3 pr-4'
			className='whitespace-nowrap'>
			{`playbooks download ${model.uuid}`}
		</FormDivInput>
		<InputAppend spacing=''>
			<Btn size='px-4 h-full' icon='clipboard' borderRadius='' onClick={onClick} />
		</InputAppend>
	</InputGroup>
);

const DownloadIconBtn = ({ loading, onClick }) => (
	<AuthBtn type='border' size='icon' icon='arrow-down-to-line' alt='download' onClick={onClick} disabled={loading} />
);

const DownloadPrimaryBtn = ({ loading, onClick }) => (
	<AuthBtn alt='download' onClick={onClick} disabled={loading}>
		{loading ? (
			<Skeleton theme={{ color: '#e5e7eb', highlight: '#d1d5db' }} className='w-20' />
		) : (
			<Span display='flex-middle' space='space-x-4'>
				<FadIcon icon='arrow-down-to-line' />
				<Span>Download</Span>
			</Span>
		)}
	</AuthBtn>
);

const DownloadSecondaryBtn = ({ loading, onClick }) => (
	<AuthBtn alt='download' onClick={onClick} disabled={loading}>
		{loading ? (
			<Skeleton theme={{ color: '#e5e7eb', highlight: '#d1d5db' }} className='w-20' />
		) : (
			<Span display='flex-middle' space='space-x-4'>
				<FadIcon icon='arrow-down-to-line' />
				<Span>Download</Span>
			</Span>
		)}
	</AuthBtn>
);

export { DownloadBtn };
